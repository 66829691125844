'use strict';
$.fn.selectRange = function(start, end) {
    var e = $(this)[0]; // I don't know why... but $(this) don't want to work today :-/
    if (!e) return;
    else if (e.setSelectionRange) { e.focus(); e.setSelectionRange(start, end); } /* WebKit */
    else if (e.createTextRange) { var range = e.createTextRange(); range.collapse(true); range.moveEnd('character', end); range.moveStart('character', start); range.select(); } /* IE */
    else if (e.selectionStart) { e.selectionStart = start; e.selectionEnd = end; }
};
var CrosswordCell = React.createClass({
    childContextTypes: {
        letter: React.PropTypes.object,
    },
    getDefaultProps: function () {

        return {
            letter: "",
            wordIndex_v: null,
            wordIndex_h: null,
            greedIndex: null,
            hold: true,
            border: "",
            correct_v: false,
            correct_h: false,
            letterIndex_v: null,
            letterIndex_h: null
        }
    },
    propTypes: {
        letter: React.PropTypes.string
    },
    getInitialState: function() {
        return this.props.object
    },
    onFocus: function(e) {

        return this.props.onFocus(e, this.state);
    },
    onBlur: function(e) {

        return this.props.onBlur(e, this.state);
    },
    onKeyUp: function(e) {
        return this.props.onKeyUp(e, this.state);
    },
    onChange: function(e) {

        return this.props.onChange(e, this.state);
    },
    componentWillMount: function() {
        this.setState(this.props.object);
    },
    render:function() {
        var className = this.state.border;
        var input_disabled = this.state.hold;

        if(this.props.object.correct_v || this.props.object.correct_h){
            className += " correct";
        }

        if(this.state.hold){
            className += " hold";
        }

        if(this.state.letter == "black"){
            return (<li className="black"></li>)
        }else if(this.state.letter == ""){
            return (<li className={this.state.border}></li>)
        }else{
            var value = this.props.object.show ? this.props.object.answer: "";
            return (<li className={className} data-num={this.props.object.string_num}><input disabled={input_disabled} onBlur={this.onBlur} onKeyUp={this.onKeyUp} onChange={this.onChange} onFocus={this.onFocus} type="text" maxLength="1" value={value}/></li>)
        }
    }

});

var CrosswordGame = React.createClass({
    getInitialState: function() {
        return {
            canvas: lang.about_us.crossword.canvas,
            greed: [],
            history: [],
            black_dots: lang.about_us.crossword.black_dots,
            words: JSON.parse(JSON.stringify(lang.about_us.crossword.words))
        };
    },
    findNextWord: function(index){

        if(this.state.words[index+1]){
            index++;
        }else{
            index = 0;
        }

        this.state.current_word = this.state.words[index];
        return this.findNextCell(this.state.words[index].cells[0], this.state.current_word.direction);

    },
    findNextCell: function(cell, direction){

        var next_index;
        var cell_out;

        var letter_index;
        var word_index;

        if(direction == "v"){
            letter_index = cell.letterIndex_v;
            word_index = cell.wordIndex_v;
        }else{
            letter_index = cell.letterIndex_h;
            word_index = cell.wordIndex_h;
        }

        var current_word = this.state.words[word_index];

        if(current_word && current_word.name.length <= letter_index+1){
            return this.findNextWord(word_index);
        }

        if(!cell_out){

            if (direction == "v") {
                next_index = cell.letterIndex_v + 1;
            }else{
                next_index = cell.letterIndex_h + 1;
            }

            cell_out = current_word.cells[next_index];


            if (cell_out && cell_out.hold) cell_out = this.findNextCell(cell_out, direction);
        }

        return cell_out;

    },
    onKeyUpLetter: function(e, cell){

        if(e.keyCode == 8){

            var input = $($("#crossword_canvas ul li").eq(cell.greedIndex)[0]).find("input")

            if(input.val()=="") {
                input = $($("#crossword_canvas ul li").eq(this.state.history[this.state.history.length - 1].greedIndex)[0]).find("input");
                $(input).selectRange(0, 1);
                input.focus();
            }

            return;

        }else if(e.keyCode == 91 || e.keyCode == 32 || e.keyCode == 17 || e.keyCode == 18){
            return;
        }else{

            this.state.history.push(cell);
            if(this.state.history.length==1){
                this.state.history.push(cell);
                this.state.history.push(cell);
                this.state.history.push(cell);
            }
        }

        var direction;

        if(cell.letterIndex_v != null && cell.letterIndex_h == null){
            direction = "v";
        }else if(cell.letterIndex_v == null && cell.letterIndex_h != null){
            direction = "h";
        }else{

            var c = this.state.history[this.state.history.length-2];
            if(!c){
                c = cell;
            }
            if(c.wordIndex_h != null && c.wordIndex_h != null && c.wordIndex_h == cell.wordIndex_h){
                direction = "h";
            }else{
                direction = "v";
            }
        }
        var _cell = this.findNextCell(cell, direction);


        if(_cell) {
            var input = $($("#crossword_canvas ul li").eq(_cell.greedIndex)[0]).find("input");
            $(input).selectRange(0, 1);
            input.focus();
        }else{
            var input = $($("#crossword_canvas ul li").eq(cell.greedIndex)[0]).find("input");
            input.blur();
        }
    },
    onFocusLetter: function(e, cell){
        $(e.target).selectRange(0, 1);
    },
    onBlurLetter: function(e, cell){
        //debugger;
    },
    onChangeLetter: function(e, cell){
        var greed_cell = this.state.greed[cell.greedIndex];

        greed_cell.answer = e.target.value.toLocaleLowerCase();

        if(greed_cell.answer != ""){
            greed_cell.show = true;
        }else{
            greed_cell.show = false;
        }

        this.state.greed[cell.greedIndex] = greed_cell;

        if(cell.wordIndex_v != null) {
            this.state.words[cell.wordIndex_v].answer[cell.letterIndex_v] = greed_cell.answer;
            this.state.words[cell.wordIndex_v].answer[cell.letterIndex_v] = greed_cell.answer;
            this.checkWord(this.state.words[cell.wordIndex_v]);
        }

        if(cell.wordIndex_h != null) {
            this.state.words[cell.wordIndex_h].answer[cell.letterIndex_h] = greed_cell.answer;
            this.state.words[cell.wordIndex_h].answer[cell.letterIndex_h] = greed_cell.answer;
            this.checkWord(this.state.words[cell.wordIndex_h]);
        }

        this.setState({greed: this.state.greed});

    },
    checkWord: function(word) {

        var label = $("label[for='crossword_"+word.string_num+""+word.direction+"']");
        label.attr("data-answer", word.answer.join(""));
        if(word.answer.join("").toLocaleLowerCase().length == word.name.toLocaleLowerCase().length){
            word.input.val(word.name);

            if(word.answer.join("").toLocaleLowerCase() == word.name.toLocaleLowerCase()){

                for(var i = 0; i < word.cells.length; i++){
                    if(word.direction == "v"){
                        this.state.greed[word.cells[i].greedIndex].correct_v = true;
                    }else{
                        this.state.greed[word.cells[i].greedIndex].correct_h = true;
                    }
                }

                label.addClass("correct");
                label.removeClass("incorrect");
            }else{
                label.addClass("incorrect");
                label.removeClass("correct");
            }
        }else{
            word.input.val(word.answer.join(""));
            label.removeClass("correct");
            label.removeClass("incorrect");
            for(var i = 0; i < word.cells.length; i++){
                if(word.direction == "v"){
                    this.state.greed[word.cells[i].greedIndex].correct_v = false;
                }else{
                    this.state.greed[word.cells[i].greedIndex].correct_h = false;
                }
            }
        }
        word.answer_string = "";
        if(word.answer.join("").toLocaleLowerCase().length == word.name.toLocaleLowerCase().length){
            word.answer_string = word.answer.join("");
        }
    },
    inputOnChange: function(e) {

        var word = this.state.words[$(e.target).attr("data-wordIndex")];
        var input_answer = $(e.target).val().toLocaleLowerCase();
        if(word.name.toLocaleLowerCase() == input_answer.toLocaleLowerCase()){

            $("label[for='crossword_"+word.string_num+""+word.direction+"']").addClass("correct");
            for(var i = 0; i < word.cells.length; i++){
                if(word.mask[i]=="*") {
                    word.answer[i] = input_answer[i];
                    this.state.greed[word.cells[i].greedIndex].answer = input_answer[i];
                }
                if(word.direction == "v"){
                    this.state.greed[word.cells[i].greedIndex].correct_v = true;
                }else{
                    this.state.greed[word.cells[i].greedIndex].correct_h = true;
                }
            }


        }else{
            $("label[for='crossword_"+word.string_num+""+word.direction+"']").removeClass("correct");

            for(var i = 0; i < word.cells.length; i++){
                if(word.mask[i]=="*") {
                    word.answer[i] = input_answer[i];
                    this.state.greed[word.cells[i].greedIndex].answer = input_answer[i];
                    this.state.greed[word.cells[i].greedIndex].show = true;
                }
                if(word.direction == "v"){
                    this.state.greed[word.cells[i].greedIndex].correct_v = false;
                }else{
                    this.state.greed[word.cells[i].greedIndex].correct_h = false;
                }
            }
        }

        this.setState({greed: this.state.greed});

    },
    componentWillMount: function() {

        var greed = [];
        for (var i = 0; i < this.state.canvas.w * this.state.canvas.h; i++) {
            greed.push({
                letter: "",
                string_num: null,
                greedIndex: i,
                hold: true,
                border:"",
                show: false,
                correct_v: false,
                correct_h: false,
                wordIndex_v: null,
                wordIndex_h: null,
                letterIndex_h: null,
                letterIndex_v: null
            });
        }

        for (var j = 0; j < this.state.words.length; j++) {
            var word = this.state.words[j];
            word.answer = [];
            word.input = $("input#crossword_"+word.string_num+""+word.direction);
            $(word.input).keyup(this.inputOnChange);
            word.input.attr("data-wordIndex", j);
            word.label = $("label[for='crossword_"+word.string_num+""+word.direction+"']");
            var start = (word.location.x) - 1 + (word.location.y-1)*this.state.canvas.w;
            for (var l = 0; l < word.name.length; l++) {
                var cell;
                if (word.direction == "h") {
                    cell = greed[start + l];
                    cell.wordIndex_h =  j;
                    cell.letterIndex_h =  l;
                }else{
                    cell = greed[start+l*this.state.canvas.w];
                    if(cell) {
                        cell.wordIndex_v = j;
                        cell.letterIndex_v = l;
                    }
                }
                if(cell) {
                    cell.letter = word.name[l];


                    if (word.mask[l] != "*") {
                        word.answer[l] = cell.letter.toLocaleLowerCase();
                        cell.show = true;
                    } else {
                        word.answer[l] = "";
                        cell.show = false;
                        cell.hold = false;
                    }


                    cell.answer = word.answer[l];
                    word.cells.push(cell);
                }
            }
            word.answer_string = "";
            greed[start].string_num = word.string_num;
        }
        for (var b = 0; b < this.state.black_dots.length; b++) {
            var start = (this.state.black_dots[b].location.x) - 1 + (this.state.black_dots[b].location.y-1)*this.state.canvas.w;
            greed[start].letter =  "black";
        }

        for(var g = 0; g < greed.length; g++){

            var border_class = "";

            if((greed[g].letter == "" && greed[g+1] && greed[g+1].letter != "" && (g+1)%this.state.canvas.w != 0) || ( g%this.state.canvas.w == this.state.canvas.w-1 && greed[g].letter != "")){
                border_class += " rb";
            }
            if((greed[g].letter == "" && greed[g-1] && greed[g-1].letter != "" && g%this.state.canvas.w != 0) || (g%this.state.canvas.w == 0 && greed[g].letter != "")){
                border_class += " lb";
            }
            if((greed[g].letter == "" && greed[g+this.state.canvas.w] && greed[g+this.state.canvas.w].letter != "") || (greed[g].letter != "" && g > greed.length - this.state.canvas.w)){
                border_class += " bb";
            }
            if((greed[g].letter == "" && greed[g-this.state.canvas.w] && greed[g-this.state.canvas.w].letter != "") || (greed[g].letter != "" && g < this.state.canvas.w)){
                border_class += " tb";
            }
            greed[g].border = border_class;
        }

        this.setState({greed: greed, ol: lang.about_us.ol});


    },
    componentDidMount: function() {
        $("#about_us #crossword #crossword_canvas ul").css({"width":"calc( 100% + " + this.state.canvas.w + "px )"});
        $("#about_us #crossword #crossword_canvas ul li").css({"padding-bottom": "calc( 100%/" + this.state.canvas.w + " )", "width":"calc( 100%/" + this.state.canvas.w + " )"});
        $("#about_us #crossword #crossword_canvas ul li.bb").css({"padding-bottom": "calc( 100%/" + this.state.canvas.w + " - 1px )", "width":"calc( 100%/" + this.state.canvas.w + " )"});
        $("#about_us #crossword #crossword_canvas ul li.tb").css({"padding-bottom": "calc( 100%/" + this.state.canvas.w + " - 1px )", "width":"calc( 100%/" + this.state.canvas.w + " )"});
        $("#about_us #crossword #crossword_canvas ul li.bb.tb").css({"padding-bottom": "calc( 100%/" + this.state.canvas.w + " - 2px )", "width":"calc( 100%/" + this.state.canvas.w + " )"});
    },
    animCell: function(cell){

    },
    skipGame_word: function(word, delay){

        var _this = this;
        setTimeout(function(){
            _this.checkWord(word);
        }, delay);
    },
    skipGame_letter: function(cell, delay){
        var _this = this;
        setTimeout(function(){
            cell.show = true;
            cell.hold = true;
            if(cell.letterIndex_h != null){
                cell.correct_h = true;
            }else{
                cell.correct_v = true;
            }
            _this.setState({words: _this.state.words});
        }, delay);
    },
    skipGame: function(){

        var speed = 80;

        var skip_cells = [];

        for(var i = 0; i < this.state.words.length; i++){
            var word = this.state.words[i];
            word.answer = word.name.split("");

            for(var j = 0; j < word.cells.length; j++){
                var letter = word.cells[j];
                if(!letter.show || letter.hold){
                    skip_cells.push(letter);
                }
                letter.finish = true;
                letter.answer = letter.letter;

            }
            this.skipGame_word(word, skip_cells.length * speed);
        }
        this.setState({words: this.state.words, solved: true});
        var skip_cells_total = skip_cells.length;
        while(skip_cells.length > 0){
            var cell = skip_cells.shift();
            this.skipGame_letter(cell, speed * (skip_cells_total - skip_cells.length));
        }

    },
    changeListAnswer: function(e){
        console.log("change: ", e);
    },
    render:function() {

        var greed = [];
        for(var i = 0; i < this.state.greed.length; i++){
            greed.push(<CrosswordCell key={i} object = {this.state.greed[i]} onKeyUp={this.onKeyUpLetter} onBlur = {this.onBlurLetter} onChange={this.onChangeLetter} onFocus={this.onFocusLetter}/>)
        }

        var _this = this;

        function inputFor(word, index){
            var ID = "crossword_"+word.string_num + word.direction;
            return (<label style={{width: (word.name.length * 2)+"%"}} key={index} htmlFor={ID}><span className="hidden_word">{word.name}</span><input id={ID} maxLength={word.name.length} type="text" onChange={_this.changeListAnswer} value={word.answer_string} placeholder={word.string_num + '' + word.direction.toUpperCase()}/></label>);
        }



        var ol = lang.about_us.ol.map(function(li, index) {
            return li.split("|||").map(function(str, index2) {
                if(index2%2==1){
                    return inputFor(_this.state.words[parseInt(str)], index2);
                }
                return (<span key={index2} dangerouslySetInnerHTML={{__html: str}}></span>);
            });
        });

        var ol_li = [];
        ol.forEach(function(li, index){
            ol_li.push((<li key={index}>{li}</li>));
        });

        return (
        <div id="crossword">
            <div className="right">
                <ol>
                    {ol_li}
                </ol>
            </div>
            <div className="left" id="crossword_canvas">
                <ul>
                    {greed}
                    <b onClick={this.skipGame}  disabled={this.state.solved} className="skip_game">{lang.skip_game}</b>
                </ul>
            </div>
        </div>
        )}
});